///////////////////////////////////////////////////////////////////////////////
// Copyright (C) 2002-2021, Open Design Alliance (the "Alliance").
// All rights reserved.
//
// This software and its documentation and related materials are owned by
// the Alliance. The software may only be incorporated into application
// programs owned by members of the Alliance, subject to a signed
// Membership Agreement and Supplemental Software License Agreement with the
// Alliance. The structure and organization of this software are the valuable
// trade secrets of the Alliance and its suppliers. The software is also
// protected by copyright law and international treaty provisions. Application
// programs incorporating this software must include the following statement
// with their copyright notices:
//
//   This application incorporates Open Design Alliance software pursuant to a
//   license agreement with Open Design Alliance.
//   Open Design Alliance Copyright (C) 2002-2021 by Open Design Alliance.
//   All rights reserved.
//
// By use of this software, its documentation or related materials, you
// acknowledge and accept the above terms.
///////////////////////////////////////////////////////////////////////////////

import React, { useState, useEffect, useContext } from "react";
import { Route, Routes } from "react-router-dom";
import { ConfigProvider } from "antd";

import { ErrorBoundary, ErrorStub, Loading, PublicRoute, PrivateRoute, withSuspense } from "./components";
import DefaultLayout from "./layout";
import { AppContext } from "./AppContext";
import "./App.css";
import { generateLocaleForAntD } from "./Localization";

const Login = withSuspense(React.lazy(() => import("./views/Login")));
const Register = withSuspense(React.lazy(() => import("./views/Register")));
const Confirm = withSuspense(React.lazy(() => import("./views/Confirm")));

function App() {
  const [loading, setLoading] = useState(true);
  const { app } = useContext(AppContext);

  useEffect(() => {
    setLoading(true);
    app
      .loginFromStorage()
      .catch((e) => console.log("Cannot login from storage.", e.message))
      .finally(() => setLoading(false));
  }, [app]);

  return (
    <ConfigProvider locale={generateLocaleForAntD()}>
      <ErrorBoundary fallback={ErrorStub}>
        <Loading loading={loading}>
          <Routes>
            <Route path="login" element={<PublicRoute user={app.user} element={<Login />} />} />
            <Route path="register" element={<PublicRoute user={app.user} element={<Register />} />} />
            <Route path="confirm" element={<PublicRoute user={app.user} element={<Confirm />} />} />
            <Route path="*" element={<PrivateRoute user={app.user} element={<DefaultLayout />} />} />
          </Routes>
        </Loading>
      </ErrorBoundary>
    </ConfigProvider>
  );
}

export default App;
