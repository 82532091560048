import React from "react";
import { ConfigService } from "./services";
import UserStorage from "./services/UserStorage";
import ClientFactory from "./ClientFactory";

export const AppContext = React.createContext();

export class AppContextProvider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      config: null,
      project: null,
    };
  }

  get user() {
    return this.state.user;
  }

  get config() {
    return this.state.config;
  }

  get project() {
    return this.state.project;
  }

  async loadConfig() {
    const config = await ConfigService.getConfig();
    this.setState({ config });
    return config;
  }

  async loginFromStorage() {
    await this.loadConfig();
    const data = UserStorage.getItem();
    if (!data) throw new Error("No user token found in the storage");
    const client = ClientFactory.get();
    const user = await client.signInWithToken(data.tokenInfo.token);
    this.setUser(user, data.rememberMe);
    return user;
  }

  logout() {
    this.setUser();
  }

  setUser(user, rememberMe) {
    if (user) {
      if (user.data) user.data.rememberMe = !!rememberMe;
      ClientFactory.get().options.data = user.customFields ?? {};
    }
    UserStorage.setItem(user?.data);
    this.setState({ user });
  }

  setProject(project) {
    this.setState({ project });
  }

  async saveUserOptions(optionsData) {
    const data = { ...this.user.data, customFields: { ...this.user.data.customfields, ...optionsData } };
    const user = await this.user.update(data);
    this.setState({ user });
  }

  render() {
    return <AppContext.Provider value={{ app: this }}>{this.props.children}</AppContext.Provider>;
  }
}
